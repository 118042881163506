@import '../../../styles/index';

.item {
  justify-content: space-between;
}

.status-badge {
  background-color: $neutral-0;
  border-radius: 0.25rem;
  border: 1px solid $surrogate-3;
  color: $surrogate-8;
  display: inline-flex;
  font-size: $font-size-12;
  font-weight: $font-weight-medium;
  height: 1.25rem;
  padding: 0 0.5rem;
}

.success {
  color: $green-9;
  border-color: $green-4;
}

.warning {
  color: $neutral-9;
  border-color: $neutral-4;
}

.error {
  color: $red-9;
  border-color: $red-4;
}
