@import '../../../styles/index';

.base {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
  flex-direction: column;
  font-size: $font-size-14;
  line-height: 1.3;
  text-align: center;
}

.icon {
  margin-bottom: 0.5rem;
}
