@import '../../../styles/index';

@keyframes back-button-intro {
  from {
    margin-left: -1rem;
    opacity: 0;
  }
  to {
    margin-left: 0;
    opacity: 1;
  }
}

.back-button {
  align-items: center;
  animation: back-button-intro $duration-default;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: $border-radius-default;
  color: $surrogate-9;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: 1.75rem;
  justify-content: center;
  transition: background-color $duration-short, color $duration-short;
  width: 1.75rem;

  &:hover {
    background-color: $surrogate-9;
    color: $neutral-0;
  }

  svg {
    height: 1.125rem;
    width: 1.125rem;
  }
}

.breadcrumb {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  margin: 1rem 1rem 0;
}

.breadcrumb-item {
  align-items: center;
  background: $surrogate-2;
  border-radius: 0.25rem;
  color: $surrogate-9;
  display: inline-flex;
  font-size: $font-size-12;
  font-weight: $font-weight-medium;
  height: 1.25rem;
  padding: 0 0.5rem;
  user-select: none;

  &:not(:first-of-type) {
    text-transform: capitalize;
  }
}

.header {
  align-items: center;
  border-bottom: 1px solid $surrogate-3;
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem;

  &.without-input {
    padding: 1rem;

    [cmdk-input] {
      height: 0px;
      width: 0px;
    }
  }
}

.inline-breadcrumbs {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}
