@import '../../styles/index';

.base {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 4rem 3rem;
}

.password {
  margin-top: 2rem;
}
