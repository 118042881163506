@import '../../../styles/index';

.table {
  padding-bottom: 1rem;

  td,
  th {
    padding: 0.5rem;
    vertical-align: top;
    font-size: $font-size-14;
    line-height: $font-size-18;
    padding: 0.75rem;
  }

  th {
    font-weight: $font-weight-bold;
    width: 33%;
    text-align: left;
  }
}
