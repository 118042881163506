@import '../../../styles/index';

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.base {
  animation: spinner 0.75s linear infinite;
  border-radius: 50%;
  border: 0.125rem solid rgba($neutral-12, 0.15);
  border-top-color: currentColor;
  height: 1.125rem;
  position: relative;
  width: 1.125rem;
}
