@import '../../../styles/index';

.base {
  align-items: center;
  border-top: 1px solid $surrogate-3;
  color: $neutral-11;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-medium;
  gap: 1rem;
  height: 2rem;
  justify-content: flex-end;
  padding: 0 0.5rem;
  position: relative;
  user-select: none;
  z-index: 2;

  &.no-border {
    border-top: none;
  }

  .popover[cmdk-root] {
    border: 1px solid $neutral-4;
    border-radius: 0.375rem;
    font-size: $font-size-14;
    margin-top: 0;
    max-height: 12.5rem;
    max-width: 25rem;
    min-width: 18.75rem;
    outline: none;

    [cmdk-empty],
    [cmdk-item] {
      gap: 0.5rem;
      height: 2rem;
      padding: 0 0.5rem;
    }

    [cmdk-group-heading] {
      font-size: $font-size-12;
      height: 1.75rem;
      padding: 0 0.5rem;
    }
  }
}

.cursor-trap {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.item {
  align-items: center;
  display: inline-flex;
  gap: 2px;

  &.is-active {
    color: $surrogate-9;

    kbd {
      background-color: $surrogate-9;
      color: $neutral-0;
    }
  }

  &.is-disabled {
    opacity: 0.25;
  }

  &.is-left {
    margin-right: auto;
  }

  span + kbd {
    margin-left: 0.25rem;
  }

  kbd {
    align-items: center;
    background-color: $neutral-4;
    border-radius: $border-radius-small;
    display: inline-flex;
    font-family: 'Euclid Enercity';
    font-size: 0.625rem;
    height: 1.125rem;
    justify-content: center;
    line-height: 1;
    min-width: 1.125rem;
    padding: 0 0.25rem;
    text-transform: uppercase;
  }
}

.addon {
  align-items: center;
  background-color: $neutral-2;
  color: $neutral-9;
  display: flex;
  font-size: $font-size-12;
  font-weight: $font-weight-medium;
  height: 2rem;
  justify-content: flex-start;
  max-width: 640px;
  padding: 0 0.5rem;
  user-select: none;

  & > span,
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
